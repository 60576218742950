<template>
    <div :class="props.classes">
        <Component
            :is="componentName(component)"
            v-for="(component, index) in content"
            :key="index"
            :content="component"
            v-bind="component.attrs.bindData"
        />
    </div>
</template>

<script setup>
import { defineProps } from 'vue';

import componentName from '@utils/component-name';

const props = defineProps({
    classes: {
        type: [String, Array],
        required: false,
        default: '',
    },
    content: {
        type: [Array, Object],
        required: false,
        default: () => [],
    },
});
</script>
